import React from "react"
import WaveData from "./data/wave.json"
import Styles from "./css/wave.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Ctadisclosureint from "src/components/ctadisclosureint"
import FooterLinks from "src/components/footerlinks_language/footer_single_language.js"

const waveData = import("./data/wave.json");


class NonbmaWaveInt extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: WaveData,
      language: props.language
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }
      this.setState({ language: this.props.language});
      if (typeof window != "undefined") {
        window.mapParams();
      }
    });
  }

  render() {
    return (
      <div className = {Styles.wave} style={{ display: this.state.display }}>
        <div className={Styles.atfGrid}>
        	<div className={Styles.header}><img src={this.state.data.topLogo} /> </div>

        	<div className={Styles.center}>
        		<h1 className="hc_param">{this.state.data.main}</h1>
        		<h3 className={Styles.subhead}>{this.state.data.subhead}</h3>

              <ul className = {Styles.bullets}>
              {this.state.data.bullets != undefined ? this.state.data.bullets.map((bullet, i) => {
                  return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                }) : null}
              </ul>
              <button className = {`${Styles.waveBtn} cl1`} data-cy="cta" onClick=
                {() => window.triggerInstall()}>{this.state.data.mainCTA}</button>
              <Ctadisclosureint data={waveData} language={this.state.language}></Ctadisclosureint>
        	</div>

        	<div className={Styles.install}>
        		<h2>{this.state.data.lowerHeadline}</h2>
        			<div className={Styles.installGrid}>
        				<div className={Styles.stepGrid}>
        					  <img className = {Styles.installIcon} src={this.state.data.step1img} />
        					<p dangerouslySetInnerHTML={{ __html: this.state.data.step1 }}></p>
        				</div>
        				<div className={Styles.stepGrid}>
                  <img className = {Styles.installIcon} src={this.state.data.step2img} />
                <p  dangerouslySetInnerHTML={{ __html: this.state.data.step2 }}></p>
        				</div>
        				<div className={Styles.stepGrid}>
                  <img className = {Styles.installIcon} src={this.state.data.step3img} />
                <p  dangerouslySetInnerHTML={{ __html: this.state.data.step3 }}></p>
        				</div>
        			</div>

              <div className={Styles.br}>
              <FooterLinks data={waveData} language={this.state.language}></FooterLinks>
              </div>
        	</div>

        </div>
            </div>






    )
  }
}

export default NonbmaWaveInt
