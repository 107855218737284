import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWaveInt from "src/components/nonbmaWaveInt"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"

const waveData = import("./data/data.json");


export default function frTideSpb() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
      #ctadisclosure-module--ctadisclaimer {
        color: #666;
        width: 50%;
        margin: 0 auto;
        line-height: 150%;
        font-size: 12px;
      }

      .wave-module--wave h1 {
        font-size: 24px;
      }



       .wave-module--wave .wave-module--header img {
         padding: 8px 0 8px 8px;

       }

       .wave-module--wave .wave-module--center {
         width: 100%;
       }

       .wave-module--wave ul.wave-module--bullets li {
         padding-bottom: 10px;
         flex: 0 1 45%!important;
       }

       .wave-module--wave ul.wave-module--bullets {
         width: 100%;
    max-width: 850px;
       }

       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;

       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

       .wave-module--wave button.wave-module--waveBtn {
         padding: 25px;
         width: 500px;
       }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>PDFtoDOC New Tab - frompdftodoc.com</title></Helmet>
    <section>
      <NonbmaWaveInt data={waveData} language='fr'>
      </NonbmaWaveInt>
      </section>
    </HomepageLayout>
  )
}
